import type { GetPaymentMethods } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import { useTypeUnsafeHttpFetch as useHttpFetch } from '@backmarket/nuxt-module-http-v2/useHttpFetch'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { camelizeKeys } from '@backmarket/utils/object/camelizeKeys'

const ENDPOINT = '/payment/payment_methods'

export function usePaymentMethods({
  countryCode = useMarketplace().market.countryCode,
} = {}) {
  const $httpFetch = useHttpFetch()

  return async function getPaymentMethods() {
    const data = await $httpFetch<GetPaymentMethods.Response>(ENDPOINT, {
      query: { country_code: countryCode },
    })

    return camelizeKeys(data)
  }
}
