import { useAsyncData, useNuxtApp } from '#imports'

import type {
  GetPaymentMethods,
  PaymentMethod,
} from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'

import { usePaymentMethods } from '../../api/usePaymentMethods/usePaymentMethods.api'
import { filterABTestedPaymentMethods } from '../helpers/filterABTestedPaymentMethods'

/**
 * Returns the cached Payment Methods for the current country
 *
 * @example
 * const { data: paymentMethods } = await useMarketPaymentMethods()
 */
export function useMarketPaymentMethods(options?: { immediate: boolean }) {
  const experiments = useExperiments()

  return useAsyncData<GetPaymentMethods.Response, unknown, PaymentMethod[]>(
    'use-market-payment-methods',
    usePaymentMethods(),
    {
      dedupe: 'defer',
      transform: (data) =>
        filterABTestedPaymentMethods(experiments, data.results),
      ...options,

      // Avoid re-fetching the data multiple on the client side
      getCachedData: (key) => useNuxtApp().payload.data[key] || undefined,
    },
  )
}
